import { graphql, Link } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import { Card, Button, Divider } from "@blueprintjs/core";
import { BlogPostCard } from "../components/blog-post-card";
import { GatsbyImage } from "gatsby-plugin-image";
import { FaGithub, FaLinkedin, FaRss, FaTwitter } from "react-icons/fa";
import { SEO, SeoObject } from "../components/seo";

export const Homepage = ({ data }) => {
    let meta: SeoObject = {
        description: data.site.siteMetadata.description,
        title: data.site.siteMetadata.title,
        image: `/og-image.jpg`,
    };

    return (
        <Layout>
            <SEO meta={meta} />

            <div className="wrapper row-gap">
                <WelcomeBanner gatsbyImageData={data.file.childImageSharp.gatsbyImageData} />
            </div>
            <div className="wrapper row-gap">
                <div className="latest-posts">
                    <div className="latest-posts-banner">Latest Posts:</div>
                    <div className="latest-posts-cards">
                        {data.allMdx.nodes.map(({ excerpt, frontmatter, fields, slug }) => (
                            <BlogPostCard {...{ excerpt, frontmatter, fields }} key={slug} />
                        ))}
                    </div>
                    <div className="latest-posts-view-more">
                        <Link to="/blog">
                            <Button icon="book">View All Posts</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

const SocialLinks = () => {
    return (
        <div className="welcomeSocialLinks">
            <a href="https://github.com/jracollins" rel="noopener noreferrer">
                <div className="welcomeSocialLink">
                    <FaGithub />
                    <div>Github</div>
                </div>
            </a>

            <a href="https://www.linkedin.com/in/jracollins" rel="noopener noreferrer">
                <div className="welcomeSocialLink">
                    <FaLinkedin />
                    <div>LinkedIn</div>
                </div>
            </a>

            <a href="https://twitter.com/jamesracollins" rel="noopener noreferrer">
                <div className="welcomeSocialLink">
                    <FaTwitter />
                    <div>Twitter</div>
                </div>
            </a>

            <a href="/rss.xml" rel="noopener noreferrer">
                <div className="welcomeSocialLink">
                    <FaRss />
                    <div>RSS</div>
                </div>
            </a>
        </div>
    );
};

const WelcomeBanner = ({ gatsbyImageData }) => {
    return (
        <Card>
            <div className="welcomebanner">
                <div className="welcomeContent">
                    <div>
                        <h1>Hi! I'm James,</h1>
                        <p>
                            a full-stack software engineer/SRE based in the UK. Rust, JS, Kubernetes and Terraform are my current preferred tools of choice, but enjoy using a wide
                            range of technologies - whatever is optimal for the job at hand.
                        </p>
                        <p>
                            Currently market-making/HFT crypto markets as a personal project (Over 1.1bn USD traded), but also interested in new opportunities, feel free to contact
                            me if applicable.
                        </p>
                        {/* <p>
                            You can find more about my experience & personal projects on my <Link to="/about/">about page</Link>.
                        </p> */}
                        <p>
                            Check my latest posts below or on my <Link to="/blog">blog page</Link>, subscribe to RSS and follow/@me on{" "}
                            <a href="https://twitter.com/jamesracollins" rel="noopener noreferrer">
                                Twitter
                            </a>
                            .
                        </p>
                    </div>
                    <div>
                        <Divider />
                    </div>
                    <SocialLinks />
                </div>
                <div className="welcomeimage">
                    <GatsbyImage image={gatsbyImageData} alt="Portrait photograph of James Collins" />
                </div>
            </div>
        </Card>
    );
};

export default Homepage;

export const query = graphql`
    query SITE_INDEX_QUERY($status: [String]!) {
        allMdx(sort: { fields: [frontmatter___date], order: DESC }, filter: { frontmatter: { status: { in: $status }, type: { eq: "blogPost" } } }, limit: 3) {
            nodes {
                id
                slug
                excerpt(pruneLength: 140)
                frontmatter {
                    title
                    slug
                    tags
                    date(formatString: "DD MMMM YYYY")
                    relativeDate: date(fromNow: true)
                    isoDate: date(formatString: "YYYY-MM-DDTHH:mm:ssZ")
                }
                fields {
                    readingTime {
                        text
                    }
                }
            }
        }
        file(relativePath: { eq: "images/james-collins.jpeg" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        site {
            siteMetadata {
                description
                title
                siteUrl
            }
        }
    }
`;
